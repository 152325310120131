#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  visibility: visible;
  opacity: 1;
  transform: rotate(0deg); 

  transition-property: visibility, opacity, transform;
  transition-duration: 0.22s;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &.done {
    visibility: hidden;
    opacity: 0;
    transform: rotate(360deg); 
  }


  img {
    animation:pulse 1.5s infinite;
  }

  @keyframes pulse {
    10% {transform: scale(1.05)}
    30% {transform: scale(1)}
    40% {transform: scale(1.05)}
    70% {transform: scale(1)}
  }

}

#loading-screen {
  background-color: hsla(356, 100%, 6%, 1);
}

