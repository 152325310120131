@import "bootstrap/scss/bootstrap";

@import "./hamburger";
@import "./main-nav";
@import "./loading-screen";

@mixin light-mode {
  .light-mode-hide {
    display: none !important;
  }
}

@mixin dark-mode {

  @include dark-mode-main-nav;

  .dark-mode-hide {
    display: none !important;
  }

  color:#fff;
  background-color:#000;
  
  .dark-mode-invert {
    /*https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-styles-with-css*/
    filter: invert(1);
  }
  .text-dark {
    color:#f8f9fa !important;
  }
  .bg-white, .card, .list-group-item, .form-control, .form-select {
    background-color:#000 !important;
    color: #f8f9fa !important;
  }
  .bg-light, .form-check-input:not(:checked) {
    background-color:#111 !important;
    color: #f8f9fa !important;
  }
  .bg-dark {
    background-color:#f8f9fa !important;
    color: #212529 !important;
  }
  .card, .list-group-item, .form-control, .form-select {
    border-color:rgba(255, 255, 255, 0.125);
  }
  .form-control, .form-select {
    color: #bbb;
  }

  .badge {
    &.bg-warning, &.bg-info, &.bg-light {
      color: #212529;
    } 
  }


  .alert-warning {
      color: #fff3cd;
      background-color: #856404;
      border-color: rgba(255, 255, 255, 0.25);
  }

}

.hamburger {
  filter: drop-shadow(0.15em 0.15em 0.2em rgba(255,255,255,0.8));
}

@media (prefers-color-scheme: dark), (prefers-color-scheme: none) {
  body.toggle-color-scheme {
    @include light-mode;
  }
  body:not(.toggle-color-scheme) {
    @include dark-mode;
  }
}
@media (prefers-color-scheme: light) {
  body.toggle-color-scheme {
    @include dark-mode;
  }
  body:not(.toggle-color-scheme) {
    @include light-mode;
  }
}

body.no-account {
  .no-account-hide {
    display:none;
  }
}
body:not(.no-account ){
  .account-hide {
    display:none;
  }
}


#vaults {
  .working .working-hide {
    display: none;
  }

  .working .working-show {
    display: block !important;
  }

  .wallet-info {
    font-size:70%;
    color:#888;
    a {
      color:#888;
    }
  }

  .vault.wallet-empty button[data-action='deposit'] {
    display:none;
  }
  .vault.vault-empty button[data-action='withdraw'] {
    display:none;
  }
  .vault.wallet-farm-empty button[data-action='stake'] {
    display:none;
  }
  .vault.farm-empty button[data-action='unstake'] {
    display:none;
  }
  .vault.farm-rewards-empty button[data-action='harvest'] {
    display:none;
  }

  .vault.wallet-empty.vault-empty.wallet-farm-empty.farm-empty.farm-rewards-empty button.fallback {
    display:inline-block !important;
  }
}

#vaults.filter-x {
  .vault.wallet-empty.vault-empty.wallet-farm-empty.farm-empty.farm-rewards-empty {
    display:none !important;
  }  
}




.language-dropdown {
  border-radius: 8px;
  list-style: none;
  display: none;
  margin-top:0.4em;
  position: absolute;
  right:0;
  background-color: #242424;
  min-width: 100px;
  font-size:14px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.language-dropdown li{
  margin:0.8em;
  color: #FDB330;
  text-align:center;
}

.language-choice, .language-dropdown li {
  cursor: pointer;
}

.language-dropdown li:hover {
  background-color: #FDB330;
  color: #121212;
}

.language-choice:hover .language-dropdown{
  display:block;
}