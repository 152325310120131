button.hamburger {
  outline: none; //undo bootstrap's outline'
  position: fixed;
  z-index: 2;
  top: 0.3em;
}

#main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: rotate(-360deg); 

  transition-property: visibility, opacity, transform;
  transition-duration: 0.22s;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &.active {
    visibility: visible;
    opacity: 1;
    transform: rotate(0); 
  }

}

#main-nav {
  background-color: hsla(30, 28%, 94%, 1);
  #main-nav-body {
    background-color: hsla(356, 28%, 88%, 1);
  }
  .bg-light {
    background-color: hsla(30, 28%, 92%, 1) !important;
  }
}

@mixin dark-mode-main-nav {

  #main-nav {
    background-color: hsla(30, 28%, 6%, 1);
    #main-nav-body {
      background-color: hsla(356, 28%, 12%, 1);
    }
    .bg-light {
      background-color: hsla(30, 28%, 8%, 1) !important;
    }
  }

    .hamburger, .hamburger.is-active {
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: #fff;
      }
    }
    .hamburger {
      filter: drop-shadow(0.15em 0.15em 0.2em rgba(0,0,0,0.8));
    }
}